<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담일지관리</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <input type="text" @keyup.enter="getReportList" v-model="value" class="w-200px h-40px pd-00 pl-20" placeholder="고객명">
                            <a @click="getReportList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div class="float-right dp-block mb-10">예약등록일검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getReportList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>상담1111일날짜</th>
                                <th>고객</th>
                                <th>상담유형</th>
                                <th>진행현황</th>
                                <th>승인현황</th>
                                <th>일지작성</th>
                            </tr>
                            <tr>
                                <td colspan="9">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>상담일111날짜</th>
                                <th>고객</th>
                                <th>상담유형</th>
                                <th>진행현황</th>
                                <th>승인현황</th>
                                <th>일지작성</th>
                            </tr>
                            <tr v-for="(item, index) of reportList" :key="index">
<!--                                <td>{{item.consDate.slice(0,10)}}</td>-->
                                <td>{{item.rownum}}</td>
                                <td>{{item.consDate2}}</td>
                                <td :class="{'underline pointer font_color':item.reportYn==='등록'}" @click="detail(item.idxReservation, item.reportYn)">{{item.clientName}}</td>
                                <td>{{item.eapType}}</td>
                                <td>{{item.processStatus}}</td>
                                <td>{{item.permission}}</td>
                                <td><span class="underline pointer" @click="preview(reportList[index])">상세내용</span></td>
                            </tr>
                            <tr v-if="reportList.length === 0">
                                <td colspan="9">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <preview :onModal.sync="onModal" :report.sync="report"></preview>
</div>
</template>
<script>
import Preview from '@/pages/counselor/journalPreview.vue'
export default {

    components: {
        Preview
    },

    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        value: '',
        idxCrmCenter: -1,
        idxCrmMetaProduct: -1,
        reportList: [],
        report: {},

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)

        onModal: false, // 미리보기 모달 on/off
        loading:true,
    }),

    mounted() {

        if(sessionStorage.getItem('pageNum') !== 'undefined' && sessionStorage.getItem('pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('pageNum'))
        }
        if(sessionStorage.getItem('value') !== 'undefined' && sessionStorage.getItem('value') !== null) {
            this.value = sessionStorage.getItem('value')
        }
        if(sessionStorage.getItem('startDate') !== 'undefined' && sessionStorage.getItem('startDate') !== null) {
            this.startDate = sessionStorage.getItem('startDate')
        }
        if(sessionStorage.getItem('endDate') !== 'undefined' && sessionStorage.getItem('endDate') !== null) {
            this.endDate = sessionStorage.getItem('endDate')
        }
        if(sessionStorage.getItem('idxCrmCenter') !== 'undefined' && sessionStorage.getItem('idxCrmCenter') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('idxCrmCenter')
        }

        this.getReportList()
    },

    methods: {

        // 상담일지 미리보기 (모달 on)
        preview(reportList) {
            this.onModal = !this.onModal
            this.report = reportList
        },

        // 상담일지 닫기 (모달 off)
        offModal() {
            this.onModal = false
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getReprotList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getReprotList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getReprotList()
            }
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 상담일지 목록
        getReportList() {
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                startDate: this.startDate,
                endDate: this.endDate,
                idxCrmCenter: this.idxCrmCenter
            }

            sessionStorage.setItem('pageNum', this.pageNum)
            sessionStorage.setItem('value', this.value)
            sessionStorage.setItem('startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('endDate', this.formatDate(this.endDate))
            sessionStorage.setItem('idxCrmCenter', this.idxCrmCenter)

            this.loading = true;

            this.axios.get('/api/v1/cons/report/list', {
                params: params
            })
            .then(res => {
                    if (res.data.err === 0) {
                        this.reportList = res.data.reportList

                        if (res.data.reportListCount) {
                            this.listLength = res.data.reportListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.reportList = []
                    }
                })
               .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                }).finally(()=>{
                    this.loading = false;
                });
        },

        // 상담일지 상세내용
        detail(idx, report) {
            if(report === '등록') {
                this.$router.push(`/convention/journal_info?idx=${idx}`)
            } else {
                false;
            }
            //window.open(`/convention/journal_info?idx=${idx}`, '_blank')
        },

    }
}
</script>
